<input
  [errorStateMatcher]="errorStateMatcher"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [id]="id"
  [isAllowSeconds]="field.templateOptions?.isAllowSeconds"
  [isShowZeroVal]="field.templateOptions?.isShowZeroVal"
  [placeholder]="to.placeholder"
  [type]="'text'"
  [isValidate]="field.templateOptions?.required"
  inputDuration
  matInput
/>
